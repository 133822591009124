import React from 'react';
import { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import mdDocument from './terms.md';
import { Box } from '@mui/material';


const Terms = () => {
	const [content, setContent] = useState("");

	useEffect(() => {
		fetch(mdDocument)
			.then(res => res.text())
			.then(md => { setContent(md) })
	})

	return (
		<Box sx={{ paddingLeft: "5rem", paddingRight: "5rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
			<Markdown children={content} />
		</Box >
	)
}

export default Terms;