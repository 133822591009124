import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from 'src/components/common/Typography';

const item: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	px: 5,
};

function ProductValues() {
	return (
		<Box
			component="section"
			sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
		>
			<Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
				<Grid container spacing={5}>
					<Grid item xs={12} md={4}>
						<Box sx={item}>
							<Typography variant="h6" sx={{ my: 5 }}>
								The experience
							</Typography>
							<Typography variant="h5" sx={{ textAlign: 'center' }}>
								{
									"Embark on an adventure through boundless universes with our AI-powered game master. Dive into complex intrigues and grand adventures. Encounter perils in untamed wildernesses, unravel palace conspiracies, or chart mysterious seas."
								}
								{
									" Every decision matters. Choose wisely, as fate is ever ready to catch you unawares."
								}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box sx={item}>
							<Typography variant="h6" sx={{ my: 5 }}>
								Our vision
							</Typography>
							<Typography variant="h5" sx={{ textAlign: 'center' }}>
								{"We are actively developing a system capable of creating infinite worlds while maintaining narrative and gameplay coherence. Our ambition is to offer a true exploration experience in a rich and consistent environment, supported by challenging rules."}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box sx={item}>
							<Typography variant="h6" sx={{ my: 5 }}>
								Be anyone you want
							</Typography>
							<Typography variant="h5" sx={{ textAlign: 'center' }}>
								{"Suggest any character you're drawn to, and the game master will tailor a scenario for you. Be it a heroic paladin, a ruthless thief, or a steadfast sailor, immerse yourself and test your limits."}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default ProductValues;