import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from 'src/components/common/Typography';

function Contact() {

	const email_contact = "anima.machine.contact@gmail.com"

	return (
		<Container
			component="section"
			sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
		>
			<Button
				component="a"
				href={`mailto:${email_contact}`}
				sx={{
					border: '4px solid currentColor',
					borderRadius: 0,
					height: 'auto',
					py: 2,
					px: 5,
				}}
			>
				<Typography variant="h5" component="span">
					Contact us !
				</Typography>
			</Button>
			<Typography variant="subtitle1" sx={{ my: 3 }}>
				If you have any question, request, or feedback, please contact us! We are still working on it, so any input is welcome! :)
			</Typography>
		</Container>
	);
}

export default Contact;