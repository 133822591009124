import * as React from 'react';
import Button from 'src/components/common/Button';
import Typography from 'src/components/common/Typography';
import backgroundImage from 'src/static/homepage_1.webp';
import HomeViewLayout from './HomeViewLayout';
import OurProject from 'src/views/homepage/OurProject';
import Contact from 'src/views/homepage/Contact';
import AppFooter from 'src/views/homepage/footer/AppFooter';
import { betaUrl } from 'src/appConfig';

// backgroundPosition: 'center',
//backgroundPosition: 'right'

export default function ProductHero() {
	return (
		<>
			<HomeViewLayout
				sxBackground={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			>
				{/* Increase the network loading priority of the background image. */}
				<img
					style={{ display: 'none' }}
					src={backgroundImage}
					alt="increase priority"
				/>
				<Typography color="inherit" align="center" variant="h1" marked="center" sx={{ paddingBottom: "10rem", fontSize: { xs: "3rem", sm: "4rm", md: "8rem" }, fontFamily: "Tahoma, sans-serif" }}>
					ANIMA MACHINE
				</Typography>
				<Typography
					color="inherit"
					align="center"
					variant="h5"
					sx={{ fontSize: { xs: "1.5rem", sm: "1.7rem", md: "2rem" }, mt: { xs: 4, sm: 10 } }}
				>
					Check out our Beta :
				</Typography>
				<Button
					color="secondary"
					variant="contained"
					size="large"
					component="a"
					href={betaUrl}
					sx={{ minWidth: 200, marginTop: "3rem", fondtSize: "2rem" }}
				>
					PLAY
				</Button>
				<Typography variant="body1" color="inherit" sx={{ mt: 2 }}>
					Discover our first game experience on ChatGPT Store.
				</Typography>
			</HomeViewLayout >
			<OurProject />
			<Contact />
			<AppFooter />
		</>

	);
}