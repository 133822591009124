import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeView from 'src/views/homepage/HomeView';
import Privacy from './views/privacy/Privacy';
import Terms from './views/terms/Terms';


export default function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
}


