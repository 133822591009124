import * as React from 'react';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from 'src/components/common/Typography';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

function Copyright() {
	return (
		<Box>
			<Typography variant="body2" color="white" align="center">
				{'Copyright © '}
				<Link color="inherit" href="#">
					Anima Machine
				</Link>{' '}
				{new Date().getFullYear()}.
			</Typography>
		</Box>

	);
}

function PrivacyLink() {
	return (
		<Box>
			<Typography variant="body2" color="white" align="center">
				<Link color="inherit" href="/privacy">
					Privacy Policy
				</Link>{' '}
			</Typography>
		</Box>

	);

}

function TermsAndConditions() {
	return (
		<Box>
			<Typography variant="body2" color="white" align="center">
				<Link color="inherit" href="/terms">
					Terms and conditions
				</Link>{' '}
			</Typography>
		</Box>

	);

}

export default function AppFooter() {
	const theme = useTheme();
	return (
		<Typography
			component="footer"
			sx={{ display: 'flex', backgroundColor: theme.palette.primary.main }}
		>
			<Container sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", paddingTop: "1.5rem", paddingBottom: "1.5rem" }}>
				<Copyright />
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<PrivacyLink />
					<TermsAndConditions />
				</Box>

			</Container>
		</Typography>
	);
}