import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const HomeViewLayoutRoot = styled('section')(({ theme }) => ({
	color: theme.palette.common.white,
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	[theme.breakpoints.up('xs')]: {
		height: '100vh',
		minHeight: 500,
		maxHeight: 2000,
	},
}));
//backgroundPosition: 'left',
// backgroundColor: 'rgba(0, 0, 0, 0.3)',

const Background = styled('div')({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: '70%',
	zIndex: -2,
});

interface HomeViewLayoutProps {
	sxBackground: SxProps<Theme>;
}

export default function ProductHeroLayout(
	props: React.HTMLAttributes<HTMLDivElement> & HomeViewLayoutProps,
) {
	const { sxBackground, children } = props;

	return (
		<HomeViewLayoutRoot>
			<Container
				sx={{
					mt: 3,
					mb: 14,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{children}
				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						backgroundColor: 'common.black',
						opacity: 0.5,
						zIndex: -1,
					}}
				/>
				<Background sx={sxBackground} />
			</Container>
		</HomeViewLayoutRoot>
	);
}